import React from 'react'
import { Link } from 'gatsby'
import { FaArrowRight } from 'react-icons/fa'
import cn from 'classnames'

const CTA = () => (
  <Link
    to="/app/signup"
    className={cn(
      'p-4 rounded cursor-pointer',
      'bg-yellow-400 text-blue-400',
      'inline-flex items-center',
      'hover:bg-yellow-300',
    )}
  >
    Join free for 7 days <FaArrowRight className="ml-2" />
  </Link>
)

import Card from '@components/Card'
import LandingLayout from '@layouts/Landing'
import { Container, H1 } from '@layouts/Landing/components'
import ConversationPng from '@assets/home/conversation.png'
import AgentPng from '@assets/home/agent.png'
import EntitiesPng from '@assets/home/entities.png'
import TranscriptPng from '@assets/home/transcript.png'

import Integrations from './Integrations'
import { styled } from '@util/tw'
import SEO from '@components/seo'

const IndexPage = () => {
  return (
    <LandingLayout>
      <SEO
        title="Make sense of live chat conversations"
        description="Sentimentor integrates with chat providers to generate sentiment analysis, topic extraction and agent performance tracking from customer conversations."
      />

      <main>
        <div className="bg-blue-400">
          <Container className="flex-wrap">
            <div className="w-full md:w-7/12">
              <H1>Make sense of live chat conversations</H1>
              <p className="text-white text-lg mb-4">
                Powerful AI for analyzing customer chats. Uncover and track
                topics and sentiment in customer communications. Improve
                customer relations, identify potential issues, and monitor chat
                agent performance and customer satisfaction.
              </p>
              <CTA />
              <p className="text-blue-200 text-sm mt-4">
                Get started in minutes. Free 7-day trial. No credit card
                required.
              </p>
            </div>
            <div className="w-full md:w-5/12">
              <img src={ConversationPng} style={{ height: '20rem' }} />
            </div>
          </Container>
        </div>

        <div>
          <Container className="flex-col text-center">
            <h2 className="text-2xl sm:text-4xl font-light">
              Ditch the star ratings, the 👍/👎 and averages.
            </h2>
            <p className="my-3 text-gray-700 text-lg">
              Get actionable insights from customer conversations.
            </p>
            <div className="flex flex-wrap justify-center">
              <Card wrap="md:w-1/4">
                <h3 className="text-xl mb-1 font-semibold">Customer Support</h3>
                <p className="text-gray-700">
                  Keep on top of chat agent performance. Analyze sentiment and
                  tone to improve agent training, find bottlenecks in your
                  support workflow.
                </p>
              </Card>
              <Card wrap="md:w-1/4">
                <h3 className="text-xl mb-1 font-semibold">Product Managers</h3>
                <p className="text-gray-700">
                  Make customer-driven decisions on your product roadmap.
                  Identify common issues and requests to inform strategy,
                  messaging and direction.
                </p>
              </Card>
              <Card wrap="md:w-1/4">
                <h3 className="text-xl mb-1 font-semibold">Customer Success</h3>
                <p className="text-gray-700">
                  Identify bottlenecks in client communications, reach clients
                  faster and more effectively. See the whole timeline of
                  customer interaction to improve satisfaction numbers.
                </p>
              </Card>
              <Card wrap="md:w-1/4">
                <h3 className="text-xl mb-1 font-semibold">
                  Marketing Strategy
                </h3>
                <p className="text-gray-700">
                  See which topics are well-received, and which to avoid. Shape
                  marketing direction and strategy using real customer data. Get
                  into the mind of users to better understand needs.
                </p>
              </Card>
            </div>
          </Container>
        </div>

        <div className="bg-blue-400 text-white">
          <Container className="flex-col text-center">
            <h2 className="text-2xl sm:text-4xl font-light">Sources</h2>
            <p className="my-3 text-lg">
              One-click integrations with the most popular live chat
              applications. Meaningful sentiment and NLP analysis in minutes
              from your conversations.
            </p>
            <Integrations />
          </Container>
        </div>

        <div>
          <Container className="flex-wrap items-center">
            <div className="w-full md:w-1/2">
              <H3>Visualize data to get to the point</H3>
              <P>
                A picture is worth a thousand words, our advanced visualizations
                are worth thousands of rows of spreadsheets and pages of agent
                scripts.
              </P>
              <P>
                Get to the point easier and more intuitively, save time and
                effort. Our reports are guaranteed to make it easier for you to
                understand clearly, and turn it into actionable insights.
              </P>
            </div>
            <div className="w-full md:w-1/2 flex justify-center">
              <img src={ConversationPng} style={{ height: '20rem' }} />
            </div>
          </Container>
        </div>
        <div>
          <Container className="flex-wrap items-center">
            <div className="w-full md:w-1/2">
              <H3>Deep Conversational Analysis</H3>
              <P>
                Extract sentiment, emotionality, tone and entities present in
                conversations. See customer satisfaction from the right angle,
                start to finish. Identify hostile interactions, reward exemplary
                conduct on a per-conversation basis.
              </P>
              <P>
                Big picture, aggregate conversations to see volume, average
                sentiment and tone to inform marketing and communication
                decisions.
              </P>
            </div>
            <div className="w-full md:w-1/2 flex justify-center">
              <img src={TranscriptPng} style={{ height: '20rem' }} />
            </div>
          </Container>
        </div>
        <div>
          <Container className="flex-wrap items-center">
            <div className="w-full md:w-1/2">
              <H3>Track Agent Performance</H3>
              <P>
                Visualize agent tone through time, track and compare to improve
                training. Just one bad interaction can turn off a customer from
                your brand for good. Devise strategies for dealing with
                difficult customers for maximum satisfaction, while tracking
                agent progress.
              </P>
              <P>
                Go beyond binary or star ratings, and get to the root of
                communication problems with laser focus. Change and shape
                behavior for the best results.
              </P>
            </div>
            <div className="w-full md:w-1/2 flex justify-center">
              <img src={AgentPng} style={{ height: '20rem' }} />
            </div>
          </Container>
        </div>
        <div>
          <Container className="flex-wrap items-center">
            <div className="w-full md:w-1/2">
              <H3>Advanced Entity and Topic Extraction</H3>
              <P>
                Go beyond simple interactions and see the big picture. Identify
                patterns in customer communciations by seeing interesting
                entities like objects, places, concepts or topics evolving over
                time.
              </P>
              <P>
                See which concepts and topics are doing well, and which ones
                need work to steer marketing direction and communication
                strategy.
              </P>
            </div>
            <div className="w-full md:w-1/2 flex justify-center">
              <img src={EntitiesPng} style={{ height: '20rem' }} />
            </div>
          </Container>
        </div>
      </main>
    </LandingLayout>
  )
}

const H3 = styled('h3', 'text-xl mb-1 font-semibold')
const P = styled('p', 'text-gray-700 mt-2')

export default IndexPage
