import React from 'react'
import { FaFileCsv } from 'react-icons/fa'

import Card from '@components/Card'

import intercomImg from '@assets/intercom.png'
import zohoImg from '@assets/zoho.png'
import driftImg from '@assets/drift.png'

const Integrations = () => (
  <div className="flex flex-wrap justify-center text-black">
    <Card wrap="md:w-1/4">
      <img src={intercomImg} className="w-16 mx-auto mb-1" />
      <h3 className="text-xl mb-1 font-semibold">Intercom</h3>
      <p className="text-gray-700">
        Pull conversations from any workspace you own.
      </p>
    </Card>
    <Card wrap="md:w-1/4">
      <div className="h-16 my-1">
        <img src={zohoImg} className="w-16 mx-auto" />
      </div>
      <h3 className="text-xl mb-1 font-semibold">Zoho SalesIQ</h3>
      <p className="text-gray-700">Pull chats from every department.</p>
      {/* <p className="text-gray-500 text-sm">Coming Soon</p> */}
    </Card>
    <Card wrap="md:w-1/4">
      <img src={driftImg} className="w-16 mx-auto mb-1" />
      <h3 className="text-xl mb-1 font-semibold">Drift</h3>
      <p className="text-gray-700">Get conversations from Drift in minutes.</p>
      {/* <p className="text-gray-500 text-sm">Coming Soon</p> */}
    </Card>
    <Card wrap="md:w-1/4">
      <FaFileCsv className="w-16 h-16 mx-auto mb-1 text-green-600" />
      <h3 className="text-xl mb-1 font-semibold">CSV</h3>
      <p className="text-gray-700">
        Export any chat transcripts and upload it as CSV for analysis.
      </p>
      {/* <p className="text-gray-500 text-sm">Coming Soon</p> */}
    </Card>
  </div>
)

export default Integrations
